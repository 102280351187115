<template>
  <div class="main-content">
    <h3 class="marginBottom20">修改个人信息</h3>
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      @submit="formSubmit"
    >
      <template slot="headPortrait">
        <FormImgUpload
          :url="form.headPortrait"
          @upload="upload"
          :disabled="false"
          :limit="1"
          :maxSize="5"
          :accept="'.png,.jpg'"
          :multiple="true"
        />
        <!-- <FormImgUpload
          :url="form.headPortrait"
          @upload="upload"
          :disabled='true'
          :limit="1"
          :maxSize="5"
          :accept="'image/png,image/jpg'"
          :multiple="true"
        /> -->
      </template>
      <template slot="menuForm">
        <el-button type="primary" @click="submit(form)">确认修改</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { useredit, userinfo } from "@/api";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { emailRegex } from "@/utils/formRules";
export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      form: {
        headPortrait: [],
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "头像",
            row: true,
            type: "text",
            prop: "headPortrait",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传头像",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "管理员姓名",
            prop: "fullName",
            maxlength: 20,
            row: true,
            // showWordLimit: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "管理员邮箱",
            prop: "email",
            maxlength: 100,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员邮箱",
                trigger: "blur",
              },
              ...emailRegex,
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.form = {
      ...this.$store.state.user.userInfo,
      headPortrait: [this.$store.state.user.userInfo.headPortrait],
    };
  },
  methods: {
        formSubmit(form, done) {},
    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;
          useredit({
            email: form.email,
            fullName: form.fullName,
            headPortrait: form.headPortrait[0],
          })
            .then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.getUserInfo();
                // done();
              }
            })
            .catch((err) => {
              that.$message.error(err.msg);
              // done();
            });
        }
      });
    },
    getUserInfo() {
      userinfo()
        .then((res) => {
          if (res.code === 200) {
            this.headPortrait = res.data.headPortrait;
            this.fullName = res.data.fullName;
            this.$store.commit("setUserInfo", res.data);
          }
        })
        .catch((err) => {
          // this.$message.error(err.msg)
        });
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.headPortrait = [...e];
      } else {
        this.form.headPortrait = [...this.form.headPortrait, e];
      }
    },
  },
};
</script>

<style scoped>
/deep/.el-icon-check {
  color: transparent!important;
}
/deep/.el-icon-check:before {
    content: ""!important;
}
</style>